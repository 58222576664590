<template>
  <div class="d-flex flex-column flex-root h-100">
      
    <div class="login login-5 login-signin-on d-flex flex-row-fluid" id="kt_login">
      <div class="d-flex flex-center bgi-size-cover bgi-no-repeat flex-row-fluid"
           style="background-image: url(/assets/media/bg/bg-2.jpg);">
        <div class="login-form text-center text-white p-7 position-relative overflow-hidden">            
            <div class="d-flex flex-center mb-15">
                <a href="#">
                <img src="/assets/media/logos/miracle-more-dark.png" class="max-h-75px" alt="" width="142" height="63"/>
                </a>
            </div>          
            <div class="mb-10">
                <h3 class="font-weight-normal">QR kodunu okutun</h3>
                <span class="col-12">Google Authenticator uygulamasını indirin sonra QR kodunu okutun. Taradıktan sonra yenileme işlemi başarıyla gerçekleşmiş olacaktır.</span>
            </div>
            <div class="mb-10">
                <img cla :src="qrCode.QR_Image" height="250" width="250" />
            </div>
            <button type="button" class="btn btn-pill btn-primary px-15 py-3" @click="codeChange()">
              {{ $t('sign_in_btn') }}
            </button>
        </div>
      </div>
    </div>    
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
  name: 'QrCode',
  computed: {
    ...mapState({
      qrCode: (state) => state.customer.qrCode
    })
  },
  methods: {
    ...mapActions('customer', ['GET_2FA_QR_CODE', 'POST_2FA_QR_CODE_CHANGE']),
    codeChange() {
      this.POST_2FA_QR_CODE_CHANGE();
    },
  },
  created() {
    this.GET_2FA_QR_CODE(this.$route.params);
  }
}
</script>

<style scoped>
</style>